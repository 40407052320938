<template>
  <div class="mainContainer">
    <div class="generatorType">
      <div class="title">Série de bon à vendre sur place</div>
      <div class="details">Génère une série de bon à imprimer à vendre sur place. Le montant et la date de validité sont renseignés à la vente.</div>
      <div class="inputWrapper" id="multipleVoucherAmount">
        <span>Créer</span>
        <input type="number" min="3" step="3" v-model="amount" placeholder="3" data-form-type=”other”>
        <span>bons</span>
      </div>
      <div class="inputWrapper" id="multipleVouchersStyle">
        <font-awesome-icon :icon="['fad', 'pen-swirl']" size="2x"/>
        <select v-model="multipleVouchersType">
          <option v-for="option in voucherTypes" v-bind:key="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div class="confirmCancelButtonsWrapper" style="margin: 0 auto;" @click="createMultiple" v-if="amount > 0">
        <font-awesome-icon :icon="['fad', 'check-circle']" size="2x" class="button" title="Créer!" v-if="!waiting"/>
        <font-awesome-icon :icon="['fad', 'spinner']" pulse size="2x" class="button" title="Création en cours" v-else/>
      </div>
    </div>
    <div class="generatorType">
      <div class="title">Bon unique</div>
      <div class="details">Génère un bon unique d'un montant donné à la date de validité définie</div>
      <div class="inputWrapper" id="singleVoucherValue">
        <font-awesome-icon :icon="['fad', 'sack-dollar']" size="2x" title="Minium 10.--"/>
        <input type="text" placeholder="0.00" v-model="singleVoucherValue" data-form-type=”other”>
        <span>CHF</span>
      </div>
      <p class="inputWrapper">
        <span><font-awesome-icon :icon="['fad', 'calendar-alt']" size="2x"/></span>
        <datepicker v-model="validityDate" :language="fr" calendar-class="calendar"></datepicker>
      </p>
      <div class="inputWrapper" id="singleVouchersStyle">
        <font-awesome-icon :icon="['fad', 'pen-swirl']" size="2x"/>
        <select v-model="singleVouchersType">
          <option v-for="option in voucherTypes" v-bind:key="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div class="inputWrapper" id="printAtHome">
        <font-awesome-icon :icon="['fadl', 'print']" size="2x"/>
        <select v-model="printAtHome">
          <option :value="true" :selected="printAtHome">Imprimé par le client</option>
          <option :value="false" :selected="!printAtHome">Imprimé par Bel-Air</option>
        </select>
      </div>
      <p class="inputWrapper pushDown" id="singleVoucherSellPrice">
        <font-awesome-icon :icon="['fad', 'percent']" size="2x" title="Prix de vente, utilisé par exemple en cas de promotion ou bons offerts"/>
        <input type="text" placeholder="0.00" v-model="sellPrice" @input="checkNumber($event, '#singleVoucherSellPrice')" data-form-type=”other”>
        <span>CHF</span>
      </p>
      <div class="confirmCancelButtonsWrapper" style="margin: 0 auto;" @click="createSingle" v-if="singleVoucherValue && sellPrice">
        <font-awesome-icon :icon="['fad', 'check-circle']" size="2x" class="button" title="Créer!" v-if="!waiting"/>
        <font-awesome-icon :icon="['fad', 'spinner']" pulse size="2x" class="button" title="Création en cours" v-else/>
      </div>
    </div>
    <div class="generatorType">
      <div class="title">PDF disponibles</div>
      <div class="details">Liste tous les pdf disponibles</div>
      <div v-for="filename in pdfs" :key="filename" class="availablePdf monospace">
        <span class="trash" title="Supprimer" @click="deleteFile(filename)"><font-awesome-icon :icon="['fad', 'trash-alt']"/></span>
        <a @click="openPdf(filename)">{{ toDate(filename) }}</a>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import fr from 'vuejs-datepicker/dist/locale'

export default {
  name: 'Create',
  components: {
    Datepicker
  },
  data: function() {
    return {
      voucherTypes: [],
      baseUrl: axios.defaults.baseURL,
      pdfs: [],
      amount: 0,
      multipleVouchersType: 'Standard',
      singleVoucherValue: '',
      singleVouchersType: 'Standard',
      printAtHome: true,
      sellPrice: '',
      waiting: false,
      validityDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      fr: fr
    }
  },
  watch: {
    'singleVoucherValue': function(newVal) {
      if (isNaN(newVal) || isNaN(parseFloat(newVal))) {
        this.singleVoucherValue = newVal.slice(0, -1)
        this.shakeError('#singleVoucherValue')
      }
      this.sellPrice = newVal
    }
  },
  onIdle() {
    this.$store.dispatch('logout')
  },
  async mounted() {
    await this.fetchPDFList()
  },
  methods: {
    checkNumber(event, onErrorElement = '') {
      if (isNaN(event.target.value) || isNaN(parseFloat(event.target.value))) {
        event.target.value = event.target.value.slice(0, -1)
        if (onErrorElement){
          this.shakeError(onErrorElement)
        }
      }
    },
    toDate(filename) {
      filename = filename.substring(0, filename.length - 4)
      const date = new Date(parseInt(filename) * 1000)
      return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()} - ${String(date.getHours()).padStart(2, '0')}h${String(date.getMinutes()).padStart(2, '0')}`
    },
    shakeError: function(elementId) {
      this.onAnimate(elementId, 'shakeError', 820)
    },
    async onAnimate(elementSelector, animation, timeout) {
      return new Promise((resolve, reject) => {
        let el = document.querySelector(elementSelector)
        if (el) {
          el.classList.remove(animation)
          el.classList.add(animation)
          setTimeout((function() {
            el = document.querySelector(elementSelector)
            if (el) {
              el.classList.remove(animation)
            }
            resolve('Done')
          }), timeout)
        } else {
          reject(`Element with selector "${elementSelector}" not found.`)
        }
      })
    },
    deleteFile(filename) {
      let self = this
      axios.delete(`/pdf/${filename}`, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      }).finally(() => {
        self.pdfs = self.pdfs.filter(item => item !== filename)
      })
    },
    async fetchPDFList() {
      let self = this
      await axios.get('/pdf', {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      }).then(response => {
        if (response.status === 200) {
          self.voucherTypes = response.data['_embedded']['pdf'][1]
          self.pdfs = response.data['_embedded']['pdf'][0].reverse()
        }
      })
    },
    async createMultiple() {
      if (isNaN(parseInt(this.amount))) {
        this.shakeError('#multipleVoucherAmount')
        return
      }
      let data = {
        'amount': this.amount,
        'multiple': true,
        'voucherType': this.multipleVouchersType,
        'printAtHome': false
      }
      await this.create(data)
    },
    async createSingle() {
      if (isNaN(parseInt(this.singleVoucherValue)) || parseInt(this.singleVoucherValue) < 10) {
        this.shakeError('#singleVoucherValue')
        return
      }
      let data = {
        'amount': this.amount,
        'value': this.singleVoucherValue,
        'validity': Math.round(Date.parse(this.validityDate) / 1000),
        'sellPrice': this.sellPrice,
        'multiple': false,
        'voucherType': this.singleVouchersType,
        'printAtHome': this.printAtHome
      }
      await this.create(data)
    },
    async create(data) {
      this.waiting = true
      let self = this
      await axios.post('/vouchers', data, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      }).then(response => {
        if (response.status === 201) {
          self.openPdf(response.data.filename)
          self.fetchPDFList()
          self.$store.dispatch('getVouchers')
          // Reset
          self.amount = 0
          self.multipleVouchersType = 'Standard'
          self.singleVouchersType = 'Standard'
          self.singleVoucherValue = ''
          self.sellPrice = ''
          self.printAtHome = true
          self.validityDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        } else {
          console.log(`Error creating vouchers: ${response.data.detail}`)
        }
        self.waiting = false
      }).catch(error => {
        console.log(error)
        self.waiting = false
      })
    },
    openPdf(filename) {
      if (!filename.endsWith('.pdf')) {
        filename = `${filename}.pdf`
      }
      axios.get(`/pdf/${filename}`, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`,
          'Accept': 'application/json'
        },
        responseType: 'arraybuffer'
      }).then(response => {
        let blob = new Blob([response.data], {type: 'application/pdf'})
        let url = window.URL.createObjectURL(blob)
        window.open(url, '_blank').focus()
      }).catch(reason => {
        console.log(reason)
      })
    }
  }
}
</script>

<style scoped>
  .inputWrapper {
    width: 50%;
    margin: 15px auto;
  }
  .pushDown {
    margin-top: 50px;
  }
  .generatorType {
    border-radius: 5px;
    width: 50%;
    margin: 15px auto;
    background-color: var(--tertiary-bg-color);
    box-sizing: border-box;
    padding: 15px;
  }
  .title {
    font-size: 1.5em;
    color: var(--secondary-text-color);
  }
  .details {
    font-style: italic;
    color: var(--secondary-text-color);
  }
  .availablePdf {
    display: flex;
    align-items: center;
    margin-top: 2px;
    width: 100%;
    background-color: var(--main-bg-color);
    height: 45px;
    min-height: 45px;
    box-sizing: border-box;
    padding: 10px;
  }
  .trash {
    font-size: 1.5em;
    margin-right: 15px;
    cursor: pointer;
  }
  .trash:hover {
    color: var(--alert-text-color);
  }
</style>
