<template>
  <div>
    <div class="mainContainer" id="mainContainer" v-if="isReady">
      <div class="tabContainer" v-show="activeTab === 0">
        <div class="inputWrapper" style="width: 500px; margin: 15px auto;">
          <span><font-awesome-icon :icon="['fad', 'qrcode']" size="2x"/></span>
          <input id="qrCodeInput" type="text" placeholder="Code du bon" v-on:input="onQRInput" v-model="scanResult" data-form-type=”other”/>
          <font-awesome-icon :icon="['fad', 'times-circle']" size="2x" class="button" @click="scanResult = ''" v-if="scanResult !== ''"/>
        </div>
				<div class="scannerContainer" v-if="voucher === null">
					<qrcode-stream :track="paintBoundingBox" @init="onInit" :camera="cameraState" @decode="onQrCode">
						<div class="loadingIndicator" v-if="cameraLoading">
							Chargement...
						</div>
					</qrcode-stream>
				</div>
        <div class="scannedVoucher" v-if="voucher !== null" id="openedVoucher">
					<div class="closeVoucher">
						<font-awesome-icon :icon="['fad', 'times-circle']" size="2x" class="button" @click="cancelVoucherEdit" title="annuler"/>
					</div>
          <div class="destroyVoucher" v-if="$store.getters.isPowerUser || voucher.value === null" id="destroyVoucherIcons">
            <font-awesome-icon :icon="['fad', 'trash-alt']" class="button" @click="askDestroyConfirm" title="supprimer" v-if="!confirmDestroy && !confirmDestroy2"/>
            <font-awesome-icon :icon="['fad', 'check-circle']" class="button" @click="askDestroyConfirm2" title="confirmer" v-if="confirmDestroy"/>
            <font-awesome-icon :icon="['fad', 'check-circle']" class="button red" @click="destroyVoucher" title="confirmer" v-if="confirmDestroy2"/>
          </div>
          <div class="voucherCode">{{ voucher.code }}</div>
          <div v-if="voucher.value !== null">
            <div class="voucherContent">
              <div class="flexColumnFull">
                <p class="voucherInfoTitle">Solde</p>
                <p class="voucherInfo" id="voucherBalanceDisplay">
                  <font-awesome-icon :icon="['fad', 'coin']" pull="left"/>
                  <span :class="{deprecated: deduction > 0}">{{ parseFloat(voucher.balance).toFixed(2) }} CHF</span>
                  <span v-if="deduction > 0" style="margin-left: 10px;">{{ Math.max(voucher.balance - deduction, 0).toFixed(2) }} CHF</span>
                  <span v-if="deduction > 0 && voucher.balance - deduction < 0" class="voucherBalanceAlert red">
                    <font-awesome-icon :icon="['fad', 'hand-holding-usd']" pull="left"/>
                    Encaisser {{ Math.abs(voucher.balance - deduction).toFixed(2) }} CHF manquant
                  </span>
                </p>
                <p class="voucherDiscountExpiredInfo" v-if="voucher.isDiscount && voucher.expired">
                  <span class="bold">Solde affiché réajusté:</span> Le bon a été vendu en promotion et la date limite a été dépassée.
                </p>
              </div>
              <div class="flexColumn">
                <p class="voucherInfoTitleLeft">Valeur du bon</p>
                <p class="voucherInfoLeft">{{ voucher.value }} CHF</p>
                <p class="voucherInfoTitleLeft">Valeur de vente</p>
                <p class="voucherInfoLeft">{{ voucher.sellPrice }} CHF</p>
              </div>
              <div class="flexColumn">
                <p class="voucherInfoTitleRight">Date de vente - Validité</p>
                <p class="voucherInfoRight" :class="{red: voucher.expired}"><font-awesome-icon :icon="['fad', 'exclamation-circle']" size="lg" v-if="voucher.expired"/> {{ timestampToDate(voucher.sellDate) }} - {{ timestampToDate(voucher.validDate) }} <span class="red" v-if="voucher.isDiscount"><br/>Validité fixe</span></p>
                <p class="voucherInfoTitleRight" v-if="voucher.seller">Vendeur</p>
                <p class="voucherInfoRight" v-if="voucher.seller">{{ voucher.seller }}</p>
                <p class="voucherInfoTitleRight" v-if="voucher.balance < voucher.value">Utilisation</p>
                <p class="voucherUsage">
                  <span v-for="(value, date) in JSON.parse(voucher.used)" :key="date">{{ timestampToDate(date, true) }} - {{ parseFloat(value).toFixed(2) }} CHF</span>
                </p>
              </div>
            </div>
            <p class="voucherInfoTitle" v-if="voucher.balance > 0" style="margin-bottom: 0;">Montant à débiter</p>
            <p class="inputWrapper" v-if="voucher.balance > 0" style="margin-top: 5px;">
              <span><font-awesome-icon :icon="['fad', 'sack-dollar']" size="2x"/></span>
              <input id="debitVoucherInput" type="text" placeholder="0.00" @input="calculateBalance" data-form-type=”other”/>
              <span>CHF</span>
            </p>
            <p class="confirmCancelButtonsWrapper" style="margin: auto 0 auto auto" v-if="deduction > 0">
              <font-awesome-icon :icon="['fad', 'times-circle']" size="2x" class="button" @click="cancelVoucherEdit" title="annuler"/>
              <font-awesome-icon :icon="['fad', 'check-circle']" size="2x" class="button" @click="deductVoucher" title="confirmer"/>
            </p>
            </div>
          <div v-else>
            <div class="voucherContent">
              <div class="flexColumn">
                <p class="voucherInfoTitle">Charger</p>
                <p class="inputWrapper" id="voucherSellBalance">
                  <span><font-awesome-icon :icon="['fad', 'sack-dollar']" size="2x"/></span>
                  <input id="creditVoucherInput" type="number" placeholder="0.00" v-model="credit" data-form-type=”other”/>
                  <span>CHF</span>
                </p>
              </div>
              <div class="flexColumn">
                <p class="voucherInfoTitle">Validité</p>
                <p class="inputWrapper" id="voucherSellValidity">
                  <span><font-awesome-icon :icon="['fad', 'calendar-alt']" size="2x"/></span>
                  <datepicker v-model="validityDate" :language="fr" calendar-class="calendar"></datepicker>
                </p>
              </div>
            </div>
            <div class="voucherContent">
              <div class="flexColumnFull">
                <p class="voucherInfoTitle">Vendeur</p>
                <p class="inputWrapper" id="voucherSeller">
                  <span><font-awesome-icon :icon="['fad', 'user']" size="2x"/></span>
                  <input id="sellerVoucherInput" type="text" v-model="seller" data-form-type=”other”/>
                </p>
              </div>
            </div>
            <div class="voucherContent">
              <div class="flexColumnFull">
                <p class="voucherInfoTitle">Valeur de vente</p>
                <p class="inputWrapper">
                  <span><font-awesome-icon :icon="['fad', 'percent']" size="2x" title="Prix de vente, utilisé par exemple en cas de promotion ou bons offerts"/></span>
                  <input id="sellPriceVoucherInput" type="number" placeholder="0.00" v-model="sellPrice" data-form-type=”other”/>
                  <span>CHF</span>
                </p>
              </div>
            </div>
            <p class="confirmCancelButtonsWrapper" style="margin: 0 auto;" v-if="credit > 0 && seller.trim().length > 0">
              <font-awesome-icon :icon="['fad', 'times-circle']" size="2x" class="button" @click="cancelVoucherEdit" title="annuler"/>
              <font-awesome-icon :icon="['fad', 'check-circle']" size="2x" class="button" @click="sellVoucher" title="vendre"/>
            </p>
          </div>
					<div class="confirmVoucherEdit" v-if="confirmVoucherEdit">Le bon a été mis à jour!</div>
					<div class="confirmVoucherEdit red" v-if="errorVoucherEdit">Erreur de mise à jour</div>
					<div class="confirmVoucherEdit" v-if="confirmVoucherDelete">Le bon a été supprimé</div>
					<div class="confirmVoucherEdit red" v-if="errorVoucherDelete">Erreur suppression</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { QrcodeStream } from 'vue-qrcode-reader'
import Datepicker from 'vuejs-datepicker'
import fr from 'vuejs-datepicker/dist/locale'
require('locutus/php/var/is_float')

export default {
  name: 'Home',
  components: {
    QrcodeStream,
    Datepicker
  },
  data: function() {
    return {
      forbiddenSellers: ['staff@bel-air.swiss', 'staff', 'bel-air', 'belair', 'buffet', 'réception', 'reception'],
      confirmDestroy: false,
      confirmDestroy2: false,
      cameraLoading: false,
      cameraState: 'auto',
      scanResult: '',
      activeTab: 0,
      voucher: null,
			deduction: 0,
      credit: '',
      sellPrice: '',
      seller: this.$store.getters.isPowerUser ? this.$store.getters.user : '',
			confirmVoucherEdit: false,
      confirmVoucherDelete: false,
      errorVoucherEdit: false,
      errorVoucherDelete: false,
      fr: fr,
      validityDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    }
  },
  watch: {
    'credit': function(newValue) {
      this.sellPrice = newValue
    }
  },
  onIdle() {
    this.$store.dispatch('logout')
  },
  computed: {
    isReady: function() {
      return this.$store.getters.isReady
    }
  },
	methods: {
		timestampToDate(sec, withHours = false) {
			if (sec > 99999999999) {
				sec = Math.round(sec / 1000)
			}
			const date = new Date(sec * 1000)
      let string = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
      if (withHours) {
        string += ` ${String(date.getHours()).padStart(2, '0')}h${String(date.getMinutes()).padStart(2, '0')}`
      }
			return string
		},
    askDestroyConfirm() {
      this.confirmDestroy = true
      let self = this
      setTimeout(function() {
        self.confirmDestroy = false
        self.shakeError('#destroyVoucherIcons')
      }, 1000)
    },
    askDestroyConfirm2() {
      this.confirmDestroy = false
      this.confirmDestroy2 = true
      let self = this
      setTimeout(function() {
        self.confirmDestroy2 = false
        self.shakeError('#destroyVoucherIcons')
      }, 1000)
    },
    async destroyVoucher() {
      this.confirmDestroy = false
      this.confirmDestroy2 = false
      let self = this
      await axios.delete(`/vouchers/${self.voucher.id}`, {
        headers: {
          'Authorization': `Bearer ${self.$store.state.token}`
        }
      }).finally(() => {
        self.$store.commit('deleteVoucher', self.voucher.code)
        self.confirmVoucherDelete = true
        setTimeout(self.hideConfirmOverlay, 2000)
      })
    },
		async deductVoucher() {
			let usage = JSON.parse(this.voucher.used)
      if (this.voucher.balance - this.deduction <= 0) {
        usage[Date.now()] = this.voucher.balance
      } else {
        usage[Date.now()] = this.deduction
      }
			await axios.patch(`/vouchers/${this.voucher.id}`, {
				'balance': Math.max(this.voucher.balance - this.deduction, 0),
				'used': JSON.stringify(usage)
			}, {
				headers: {
					'Authorization': `Bearer ${this.$store.state.token}`
				}
			}).then(response => {
				this.$store.commit('receivedVoucher', response.data)
				this.confirmVoucherEdit = true
				setTimeout(this.hideConfirmOverlay, 2000)
			}).catch(() => {
        this.errorVoucherEdit = true
        setTimeout(this.hideConfirmOverlay, 5000)
			})
		},
    async updateVoucherAdjustedValueAndBalance(newAdjustedValue, newBalance) {
      await axios.patch(`/vouchers/${this.voucher.id}`, {
        'adjustedValue': newAdjustedValue,
        'balance': newBalance
      }, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      }).then(response => {
        this.$store.commit('receivedVoucher', response.data)
      }).catch(() => {
        this.errorVoucherEdit = true
        setTimeout(this.hideConfirmOverlay, 5000)
      })
    },
    async sellVoucher() {
      if (this.forbiddenSellers.includes(this.seller.trim().toLowerCase()) || this.seller.trim().length === 0) {
        this.shakeError('#voucherSeller')
        return
      }
      if (Date.parse(this.validityDate) < Date.now()) {
        this.shakeError('#voucherSellValidity')
        return
      }
      if (parseInt(this.credit) < 10) {
        this.shakeError('#voucherSellBalance')
        return
      }
      await axios.patch(`/vouchers/${this.voucher.id}`, {
        'sellDate': Math.round(Date.now() / 1000),
        'validDate': Math.round(Date.parse(this.validityDate) / 1000),
        'value': parseInt(this.credit),
        'balance': parseFloat(this.credit),
        'sellPrice': parseFloat(this.sellPrice),
        'seller': this.seller,
        'used': '{}'
      }, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      }).then(response => {
        this.$store.commit('receivedVoucher', response.data)
        this.seller = this.$store.getters.isPowerUser ? this.$store.getters.user : ''
        this.sellPrice = ''
        this.confirmVoucherEdit = true
        setTimeout(this.hideConfirmOverlay, 2000)
      }).catch((error) => {
        console.log(`Error selling voucher: ${error.message}`)
        this.errorVoucherEdit = true
        setTimeout(this.hideConfirmOverlay, 5000)
      })
    },
		hideConfirmOverlay(cancelEdit = true) {
			this.deduction = 0
			this.confirmVoucherEdit = false
			this.confirmVoucherDelete = false
			this.errorVoucherEdit = false
			this.errorVoucherDelete = false
      if (cancelEdit) {
        this.cancelVoucherEdit()
      }
		},
		async cancelVoucherEdit() {
      let self = this
      await this.onAnimate('#openedVoucher', 'shrinkAway', 250).then(() => {
        self.scanResult = ''
        self.voucher = null
        self.deduction = 0
        self.credit = ''
        self.seller = ''
        self.sellPrice = ''
      }).catch(reason => {
        console.error(reason)
      })
		},
    shakeError: function(elementId) {
      this.onAnimate(elementId, 'shakeError', 820)
    },
    attention: function(elementId) {
      this.onAnimate(elementId, 'attention', 1000)
    },
    async onAnimate(elementSelector, animation, timeout) {
      return new Promise((resolve, reject) => {
        let el = document.querySelector(elementSelector)
        if (el) {
          el.classList.remove(animation)
          el.classList.add(animation)
          setTimeout((function() {
            el = document.querySelector(elementSelector)
            if (el) {
              el.classList.remove(animation)
            }
            resolve('Done')
          }), timeout)
        } else {
          reject(`Element with selector "${elementSelector}" not found.`)
        }
      })
    },
    calculateBalance(event) {
      if (isNaN(event.target.value) || isNaN(parseFloat(event.target.value))) {
        event.target.value = event.target.value.slice(0, -1)
      }
      if (event.target.value > 0) {
        this.attention('#voucherBalanceDisplay')
      }
			this.deduction = event.target.value
    },
    async onInit (promise) {
      this.cameraLoading = true

      try {
        await promise
      } catch (error) {
        console.error(error)
      } finally {
        this.cameraLoading = false
      }
    },
    async onQrCode(result) {
      this.scanResult = result
      let target = document.getElementById('qrCodeInput')
      if (result.toUpperCase() in this.$store.state.vouchers) {
        target.classList.remove('codeNotFound')
        this.voucher = this.$store.state.vouchers[result.toUpperCase()]
				this.scanResult = ''
        this.voucher.expired = Date.now() / 1000 > this.voucher.validDate
        this.voucher.isDiscount = this.voucher.sellPrice < this.voucher.value

        if (this.voucher.isDiscount && this.voucher.expired) {
          if (this.voucher.adjustedValue !== this.voucher.sellPrice) {
            this.voucher.adjustedValue = this.voucher.sellPrice
            this.voucher.balance = Math.max(this.voucher.sellPrice - (this.voucher.value - this.voucher.balance), 0)
            await this.updateVoucherAdjustedValueAndBalance(this.voucher.sellPrice, this.voucher.balance)
          }
        }

      } else {
        target.classList.add('codeNotFound')
        this.voucher = null
      }
      this.pause()
      await this.timeout(500)
      this.resume()
    },
    paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },
    pause: function() {
      this.cameraState = 'off'
    },
    resume: function() {
      this.cameraState = 'auto'
    },
    timeout: function(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    onQRInput: function(event) {
      let value = event.target.value.trim()
      let found = 0
      let autoCode = ''
      for (const code in this.$store.getters.vouchers) {
        if (code.toLowerCase().startsWith(value.toLowerCase())) {
          found++
          autoCode = code
        }
        if (found > 1) {
          autoCode = ''
          break
        }
      }
      if (autoCode !== '') {
        event.target.value = autoCode
      }
      if (event.target.value.trim().length === 12) {
				this.onQrCode(event.target.value.trim())
      } else {
        event.target.classList.add('codeNotFound')
        this.voucher = null
				if (event.target.value.trim().length > 12) {
					this.scanResult = event.target.value.trim().slice(0, -1)
				}
      }
    }
  }
}
</script>

<style scoped>

.scannerContainer {
	width: 500px;
	margin: 15px auto;
	border: 2px solid var(--main-bg-color);
	border-radius: 5px;
}

.tabContainer {
  width: 50%;
  margin: 0 auto;
}

::placeholder {
  font-style: italic;
  color: var(--tertiary-text-color);
  padding-right: 2px;
}

/*noinspection CssUnusedSymbol*/
.codeNotFound {
  color: red;
}

.scannedVoucher {
	position: relative;
  min-height: 50px;
  width: 500px;
  margin: 15px auto;
  background-color: var(--hover-bg-color);
  box-sizing: border-box;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.25em;
}

.voucherCode {
  box-sizing: border-box;
  padding: 5px;
	background-color: var(--tertiary-bg-color);
  border: 2px dashed var(--secondary-bg-color);
	color: var(--main-bg-color);
	font-weight: bold;
	text-transform: uppercase;
  width: 250px;
  text-align: center;
  margin: 0 auto;
}

.voucherInfo {
  font-weight: bold;
  font-size: 125%;
}

.voucherInfoTitle {
  color: var(--tertiary-text-color);
  font-size: 125%;
}

.voucherInfoTitleRight {
	color: var(--main-bg-color);
	font-size: 0.7em;
	font-weight: bold;
	text-align: right;
}

.voucherInfoTitleLeft {
  color: var(--main-bg-color);
  font-size: 0.7em;
  font-weight: bold;
  text-align: left;
}

.voucherInfoRight {
	font-weight: bold;
	font-size: 0.7em;
	text-align: right;
}

.voucherInfoLeft {
  font-weight: bold;
  font-size: 0.7em;
  text-align: left;
}

.loadingIndicator {
  margin-top: 50px;
  width: 100%;
  text-align: center;
  color: var(--main-bg-color);
  font-size: 1.5em;
}

#qrCodeInput {
  text-align: center;
  text-transform: uppercase;
}

/*noinspection CssUnusedSymbol*/
.deprecated {
	text-decoration: line-through;
	color: red;
	font-style: italic;
}

.confirmVoucherEdit {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	background-color: rgba(0, 0, 0, 0.75);
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	font-size: 2em;
}

.closeVoucher {
	position: absolute;
	top: 5px;
	right: 5px;
}

.destroyVoucher {
  position: absolute;
  top: 5px;
  left: 5px;
}

.voucherUsage {
	padding-left: 10px;
	box-sizing: border-box;
  font-size: 90%;
}

.voucherUsage > span {
	display: block;
	font-style: italic;
	font-size: 0.7em;
	text-align: right;
}

.red {
	color: var(--alert-text-color);
}

.voucherContent {
	display: flex;
	justify-content: space-evenly;
  flex-wrap: wrap;
}

.flexColumn {
	width: 50%;
  padding: 10px;
  box-sizing: border-box;
}

.flexColumnFull {
  width: 100%;
  margin: 5px;
}

.voucherBalanceAlert {
  display: block;
  font-size: 75%;
  margin-top: 10px;
}

.voucherDiscountExpiredInfo {
  font-size: 75%;
  font-style: italic;
}
</style>
