<template>
  <div class="mainContainer">
    <div class="inputWrapper">
      <span><font-awesome-icon :icon="['fad', 'chart-bar']" size="2x"/></span>
      <select v-model="show" @change="fillData">
        <option value="annee">Année</option>
        <option value="mois">Mois</option>
        <option value="semaine">Semaine</option>
        <option value="date">Date</option>
        <option value="jour">Jour</option>
      </select>
    </div>
    <div class="inputWrapper" v-if="show !== 'annee'">
      <span><font-awesome-icon :icon="['fad', 'calendar-alt']" size="2x"/></span>
      <select v-model="showYear" @change="fillData">
        <option value="*">Global</option>
        <option v-for="option in Object.keys(yearData).reverse()" v-bind:key="option">
          {{ option }}
        </option>
      </select>
    </div>
    <div class="chart">
      <chart v-if="loaded" :chart-data="dataCollection" :options="chartOptions"/>
    </div>
    <div class="stats" v-if="loaded">
      <div class="statsTitle">Rapports totaux</div>
      <div class="statsTable" id="statsTable">
        <div class="statsRowHeader">
          <div>&nbsp;</div>
          <div class="align-right">Nombre</div>
          <div class="align-right">Montant</div>
        </div>
        <div class="statsRow">
          <div>Vente de bon</div>
          <div class="align-right">{{ totalAmountSold }}</div>
          <div class="align-right">{{ totalValueSold.toFixed(2) }} chf</div>
        </div>
        <div class="statsRow">
          <div>Bon utilisés</div>
          <div class="align-right">{{ totalAmountUsed }}</div>
          <div class="align-right">{{ totalValueUsed.toFixed(2) }} chf</div>
        </div>
        <div class="statsRow">
          <div>Bon hors date</div>
          <div class="align-right">{{ totalAmountExpired }}</div>
          <div class="align-right">{{ totalValueExpired.toFixed(2) }} chf</div>
        </div>
        <div class="statsRow">
          <div>Potentiels retours</div>
          <div class="align-right">{{ (totalAmountSold - totalAmountUsed).toFixed(0) }}</div>
          <div class="align-right">{{ (totalValueSold - totalValueUsed).toFixed(2) }} chf</div>
        </div>
        <div class="statsRow">
          <div>Potentiels retours sans expirés</div>
          <div class="align-right">{{ (totalAmountSold - totalAmountUsed - totalAmountExpired).toFixed(0) }}</div>
          <div class="align-right">{{ (totalValueSold - totalValueUsed - totalValueExpired).toFixed(2) }} chf</div>
        </div>
      </div>
      <div class="statsTitle">Rapports annuels</div>
      <div class="annualStatsCardsHolder">
        <div class="annualStatsCard" v-for="(data, year) in reportYearly" :key="year">
          <div class="statsRowHeader">
            <div class="align-left bold">{{ year }}</div>
            <div class="align-right">Nombre</div>
            <div class="align-right">Montant</div>
          </div>
          <div class="statsRow">
            <div>Vente</div>
            <div class="align-right">{{ data['sales']['amount'] }}</div>
            <div class="align-right">{{ data['sales']['total'].toFixed(2) }} chf</div>
          </div>
          <div class="statsRow">
            <div>Rachat</div>
            <div class="align-right">{{ data['intake']['amount'] }}</div>
            <div class="align-right">{{ data['intake']['total'].toFixed(2) }} chf</div>
          </div>
          <div class="statsRow">
            <div>Offerts</div>
            <div class="align-right">{{ data['gifted']['amount'] }}</div>
            <div class="align-right">{{ data['gifted']['total'].toFixed(2) }} chf</div>
          </div>
          <div class="statsRow">
            <div title="Montant 'offert' calculé avec: valeur du bon - prix de vente du bon">Promo</div>
            <div class="align-right">{{ data['discounted']['amount'] }}</div>
            <div class="align-right">{{ data['discounted']['total'].toFixed(2) }} chf</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '@/components/Chart'

export default {
  name: 'Stats',
  components: {
    Chart
  },
  data: function() {
    return {
      totalAmountSold: 0,
      totalValueSold: 0,
      totalAmountExpired: 0,
      totalValueExpired: 0,
      totalAmountUsed: 0,
      totalValueUsed: 0,
      reportYearly: {},
      defaultYearlyObject: {'sales': {'amount': 0, 'total': 0}, 'intake': {'amount': 0, 'total': 0}, 'gifted': {'amount': 0, 'total': 0}, 'discounted' : {'amount': 0, 'total': 0}},
      show: 'mois',
      showYear: '*',
      loaded: false,
      dataCollection: null,
      graphData: {},
      yearData: {},
      monthData: {},
      weekData: {},
      dayData: {},
      dateData: {},
      days: {
        0: 'Dimanche',
        1: 'Lundi',
        2: 'Mardi',
        3: 'Mercredi',
        4: 'Jeudi',
        5: 'Vendredi',
        6: 'Samedi'
      },
      months: {
        0: 'Janvier',
        1: 'Février',
        2: 'Mars',
        3: 'Avril',
        4: 'Mai',
        5: 'Juin',
        6: 'Juillet',
        7: 'Août',
        8: 'Septembre',
        9: 'Octobre',
        10: 'Novembre',
        11: 'Décembre'
      },
      chartOptions: {
        legend: {
          display: true,
          position: 'top',
          labels: {
            fontColor: 'white',
            fontSize: 20
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: 'white',
              fontSize: 18,
              beginAtZero: true
            }
          }],
          xAxes: [{
            ticks: {
              fontColor: 'white',
              fontSize: 14,
              beginAtZero: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            anchor: 'end',
            clamp: true,
            align: 'end',
            rotation: -45,
            overlap: 'auto'
          },
          tooltip: {
            enabled: false,
          }
        }
      }
    }
  },
  onIdle() {
    this.$store.dispatch('logout')
  },
  mounted() {
    let graphData = {}
    let yearData = {}
    let monthData = {}
    let weekData = {}
    let dayData = {}
    let dateData = {}

    let now = new Date()
    for (const voucher of Object.values(this.$store.getters.vouchers)) {
      if (voucher.sellDate != null) {
        this.totalAmountSold++
        this.totalValueSold += parseFloat(voucher.value)

        let dateObject = new Date(voucher.sellDate * 1000)
        let year = dateObject.getFullYear()
        let month = String(dateObject.getMonth()).padStart(2, '0')
        const week = String(dateObject.getWeek()).padStart(2, '0')
        const day = dateObject.getDay()
        const date = String(dateObject.getDate()).padStart(2, '0')
        if (year > now.getFullYear() || (year === now.getFullYear() && parseInt(month) > now.getMonth())) {
          console.warn(`Vente impossible car dans le future: ${voucher.code}`)
          if (year > now.getFullYear()) {
            console.warn("Correction de l'année sur l'année courante")
            year = now.getFullYear()
          } else {
            console.warn('Correction automatique impossible, erreur de mois')
            continue
          }
        }
        if (!{}.hasOwnProperty.call(yearData, year)) {
          yearData[year] = []
        }
        if (!{}.hasOwnProperty.call(monthData, month)) {
          monthData[month] = []
        }
        if (!{}.hasOwnProperty.call(weekData, week)) {
          weekData[week] = []
        }
        if (!{}.hasOwnProperty.call(dayData, day)) {
          dayData[day] = []
        }
        if (!{}.hasOwnProperty.call(dateData, date)) {
          dateData[date] = []
        }

        if (!{}.hasOwnProperty.call(this.reportYearly, year)) {
          this.reportYearly[year] = JSON.parse(JSON.stringify(this.defaultYearlyObject))
        }

        yearData[year].push(voucher)
        monthData[month].push(voucher)
        weekData[week].push(voucher)
        dayData[day].push(voucher)
        dateData[date].push(voucher)

        if (voucher.value !== null && voucher.value > 0) {
          this.reportYearly[year]['sales']['amount'] += 1
          this.reportYearly[year]['sales']['total'] += voucher.value
        }

        if (voucher.value !== null && voucher.value > 0 && voucher.sellPrice === 0) {
          this.reportYearly[year]['gifted']['amount'] += 1
          this.reportYearly[year]['gifted']['total'] += voucher.value
        }

        if (voucher.value !== null && voucher.value > 0 && voucher.sellPrice !== voucher.value && voucher.sellPrice > 0) {
          this.reportYearly[year]['discounted']['amount'] += 1
          this.reportYearly[year]['discounted']['total'] += voucher.value - voucher.sellPrice
        }

        if (voucher.used !== '{}') {
          let usage = JSON.parse(voucher.used)
          for (const [usageDate, usageAmount] of Object.entries(usage)) {
            let dateObj = new Date(parseInt(usageDate))
            let usageYear = dateObj.getFullYear()
            if (!{}.hasOwnProperty.call(this.reportYearly, usageYear)) {
              this.reportYearly[usageYear] = JSON.parse(JSON.stringify(this.defaultYearlyObject))
            }
            this.reportYearly[usageYear]['intake']['amount'] += 1
            this.reportYearly[usageYear]['intake']['total'] += parseFloat(usageAmount)
          }
        }

        // graphData[ YEAR ][ MONTH ][ DAY ][ VOUCHERS ]
        if (!{}.hasOwnProperty.call(graphData, year)) {
          graphData[year] = {}
          graphData[year][month] = {}
          graphData[year][month][day] = [voucher]
        } else if (!{}.hasOwnProperty.call(graphData[year], month)) {
          graphData[year][month] = {}
          graphData[year][month][day] = [voucher]
        } else if (!{}.hasOwnProperty.call(graphData[year][month], day)) {
          graphData[year][month][day] = [voucher]
        }
      }

      if (voucher.balance > 0 && voucher.validDate < Math.floor(now.getTime()) / 1000) {
        this.totalValueExpired += parseFloat(voucher.balance)
        this.totalAmountExpired++
      }

      if (voucher.used !== '{}') {
        if (parseFloat(voucher.balance) <= 0) {
          this.totalValueUsed += parseFloat(voucher.value)
          this.totalAmountUsed++
        }
      }
    }

    this.graphData = graphData
    this.monthData = monthData
    this.weekData = weekData
    this.dayData = dayData
    this.dateData = dateData
    this.yearData = yearData
    this.fillData()
    this.loaded = true
  },
  methods: {
    increment: function(date, label) {
      if (this.show === 'mois' && this.months[date.getMonth()] === label) {
        return true
      } else if (this.show === 'semaine' && String(date.getWeek()).padStart(2, '0') === label) {
        return true
      } else if (this.show === 'jour' && this.days[date.getDay()] === label) {
        return true
      } else if (this.show === 'annee' && String(date.getFullYear()) === label) {
        return true
      } else if (this.show === 'date' && String(date.getDate()).padStart(2, '0') === label) {
        return true
      }
      return false
    },
    fillData: function() {
      let labels
      if (this.show === 'mois') {
        labels = Object.keys(this.monthData)
      } else if (this.show === 'semaine') {
        labels = Object.keys(this.weekData)
      } else if (this.show === 'jour') {
        labels = Object.keys(this.dayData)
      } else if (this.show === 'date') {
        labels = Object.keys(this.dateData)
      } else {
        this.showYear = '*'
        labels = Object.keys(this.yearData)
      }

      labels.sort()
      if (this.show === 'jour') {
        for (const label of [...labels]) {
          labels.shift()
          labels.push(this.days[label])
        }
        let sunday = labels.shift()
        labels.push(sunday)
      } else if (this.show === 'mois') {
        for (const label of [...labels]) {
          labels.shift()
          labels.push(this.months[parseInt(label)])
        }
      }

      let sellData = []
      let usageData = []
      let sellValueData = []
      let usageValueData = []
      for (let label of labels) {
        let sellCount = 0
        let usageCount = 0
        let sellValue = 0
        let usageValue = 0
        for (const voucher of Object.values(this.$store.getters.vouchers)) {
          if (voucher.sellDate === null) {
            continue
          }
          let sellDate = new Date(voucher.sellDate * 1000)
          if (this.increment(sellDate, label)) {
            if (this.showYear === '*' || sellDate.getFullYear() === parseInt(this.showYear)) {
              sellCount++
              sellValue += parseInt(voucher.value)
            }
          }

          if (voucher.used === '{}') {
            continue
          }

          let usage = JSON.parse(voucher.used)
          for (const [date, amount] of Object.entries(usage)) {
            const usageDate = new Date(parseInt(date))
            if (this.increment(usageDate, label)) {
              if (this.showYear === '*' || usageDate.getFullYear() === parseInt(this.showYear)) {
                usageCount++
                usageValue += parseFloat(amount)
              }
            }
          }
        }
        sellData.push(sellCount)
        usageData.push(usageCount)
        sellValueData.push(sellValue.toFixed(2))
        usageValueData.push(usageValue.toFixed(2))
      }

      let dataset = [
        {
          label: 'Vente',
          backgroundColor: '#3374FF',
          data: sellData
        },
        {
          label: 'Encaissement',
          backgroundColor: '#33FF74',
          data: usageData
        },
        {
          label: 'Valeur vente',
          backgroundColor: '#ff6733',
          data: sellValueData,
          hidden: true
        },
        {
          label: 'Valeur encaissement',
          backgroundColor: '#006720',
          data: usageValueData,
          hidden: true
        }
      ]

      this.dataCollection = {
        labels: labels,
        datasets: dataset
      }
    }
  }
}
</script>

<style scoped>
 .chart {
   position: relative;
   width: 95%;
   margin: 50px auto;
 }
 .inputWrapper {
   margin: 15px 50px;
   width: 300px;
 }
 .stats {
   margin-bottom: 25px;
 }
 .statsTitle {
   width: 100%;
   text-align: center;
   color: var(--main-text-color);
   font-size: 2em;
 }
 .statsTable, .annualStatsCard {
   width: 600px;
   margin: 30px auto;
   display: table;
   border-spacing: 2px;
   background-color: var(--main-bg-color);
 }
 .annualStatsCard {
   margin: 5px;
 }
 .statsRow, .statsRowHeader {
   display: table-row;
 }
 .statsRow > div {
   background-color: var(--secondary-bg-color);
 }
 .statsRow > div,
 .statsRowHeader > div {
   display: table-cell;
   width: auto;
   height: 30px;
   padding: 10px;
   vertical-align: middle;
 }
 .statsRowHeader > div {
   font-weight: bold;
 }
 .align-right {
   text-align: right;
 }
 .align-left {
   text-align: left;
 }
 .annualStatsCardsHolder {
   width: 600px;
   margin: 15px auto;
   display: flex;
   flex-direction: column-reverse;
   flex-wrap: wrap;
   justify-content: space-evenly;
 }
</style>
