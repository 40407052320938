import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Stats from '../views/Stats.vue'
import Create from '../views/Create.vue'

Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {requiresAuth: true, requirePowerUser: false}
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {requiresAuth: false, requirePowerUser: false}
	},
	{
		path: '/stats',
		name: 'Stats',
		component: Stats,
		meta: {requiresAuth: true, requirePowerUser: true}
	},
	{
		path: '/generate',
		name: 'Create',
		component: Create,
		meta: {requiresAuth: true, requirePowerUser: true}
	}
]
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta['requiresAuth'])) {
		if (store.getters.isConnected) {
			if (to.meta['requirePowerUser'] && !store.getters.isPowerUser) {
				return
			}
			next()
		} else {
			next('/login')
		}
	} else {
		next()
	}
})

export default router
