<template>
	<ul class="nav">
    <router-link to="/" custom v-slot="{ navigate }">
      <li @click="navigate">Scanner</li>
    </router-link>
    <router-link to="/Generate" custom v-slot="{ navigate }">
      <li @click="navigate" v-if="$store.getters.isPowerUser">Créer</li>
    </router-link>
    <router-link to="/Stats" custom v-slot="{ navigate }">
      <li @click="navigate" v-if="$store.getters.isPowerUser">Statistiques</li>
    </router-link>
		<li v-if="loggedIn" @click="logout">Déconnecter</li>
	</ul>
</template>

<script>
export default {
	name: 'Nav',
  data: function() {
    return {

    }
  },
	computed: {
		loggedIn: function() {
			return this.$store.getters.isConnected
		}
	},
	methods: {
		async logout() {
			await this.$store.dispatch('logout')
		}
	}
}
</script>

<style scoped>
.nav {
	display: flex;
	height: 100px;
	margin: 0;
	flex-direction: row;
	justify-content: center;
}

.nav li {
	list-style-type: none;
	display: flex;
	padding: 0;
	margin: 15px;
	background-color: var(--secondary-bg-color);
	width: 300px;
  height: 70px;
  border-radius: 5px;
	align-content: center;
	justify-content: center;
	align-items: center;
	font-size: 1.5em;
}

.nav li:hover {
	cursor: pointer;
	background-color: var(--hover-bg-color);
}

.nav .activeTab {
	background-color: var(--tertiary-bg-color);
}

a {
	text-decoration: none;
}

a:hover {
	color: var(--main-text-color)
}
</style>
