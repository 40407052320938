import Vuex from 'vuex'
import Vue from 'vue'
//import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import router from '../router'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
	state: {
		token: null,
		refreshToken: null,
		user: null,
		vouchers: {},
		ready: false,
		loggingIn: false,
		loadingData: false,
		powerUsers: ['Laurent', 'Natalija']
	},
	getters: {
		isReady: state => state.ready,
		isConnected: state => !!state.user,
		user: state => state.user,
		vouchers: state => state.vouchers,
		token: state => state.token,
		loggingIn: state => state.loggingIn,
		loadingData: state => state.loadingData,
		powerUsers: state => state.powerUsers,
		isPowerUser: state => state.powerUsers.includes(state.user)
	},
	actions: {
		async login({commit}, Data) {
			Data.set('client_id', 'any')
			commit('loggingIn', true)
			await axios.post('/oauth', Data).then(async response => {
				commit('loggingIn', false)
				await commit('setToken', response.data['access_token'])
				await commit('setRefreshToken', response.data['refresh_token'])
				await commit('setUsername', Data.get('username'))
			})
		},
		async logout({commit}) {
			let user = null
			commit('logout', user)
			await router.push('/login')
		},
		async getVouchers({commit}) {
			commit('loadingData', true)
			await axios.get(`/vouchers`, {
				headers: {
					'Authorization': `Bearer ${this.state.token}`
				}
			}).then(async response => {
				if ('page_count' in response.data) {
					let pageCount = response.data['page_count']

					for (let i = 1; i <= pageCount; i++) {
						await axios.get(`/vouchers?page=${i}`, {
							headers: {
								'Authorization': `Bearer ${this.state.token}`
							}
						}).then(response => {
							let vouchers = {}
							for (const voucher of response.data['_embedded']['vouchers']) {
								vouchers[voucher['code']] = voucher
							}
							commit('addVouchers', vouchers)
						})
					}
				}
				commit('loadingData', false)
				commit('setReady', true)
			})
		},
		async fetchVoucherPage({commit}, url) {
			await axios.get(url, {
				headers: {
					'Authorization': `Bearer ${this.state.token}`
				}
			}).then(response => {
				for (const voucher of response.data['_embedded']['vouchers']) {
					commit('addVouchers', voucher)
				}
				if ('next' in response.data['_link']) {
					return response.data['_link']['next']
				} else {
					return ''
				}
			})
		}
	},
	mutations: {
		async setUsername(state, username) {
			state.user = username
		},
		async setToken(state, token) {
			state.token = token
		},
		async setRefreshToken(state, token) {
			state.refreshToken = token
		},
		logout(state) {
			state.user = null
			state.token = null
			state.refreshToken = null
			state.vouchers = {}
			state.ready = false
			state.loggingIn = false
			state.loadingData = false
		},
		setVouchers(state, data) {
			state.vouchers = data
		},
		addVouchers(state, data) {
			state.vouchers = Object.assign(this.state.vouchers, data)
		},
		receivedVoucher(state, data) {
			state.vouchers[data.code] = data
		},
		deleteVoucher(state, code) {
			Vue.delete(state.vouchers, code)
		},
		loggingIn(state, data) {
			state.loggingIn = data
		},
		loadingData(state, data) {
			state.loadingData = data
		},
		setReady(state, data) {
			state.ready = data
		}
	},
	//plugins: [createPersistedState()]
})
